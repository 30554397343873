// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Icons
@import '~bootstrap-icons/font/bootstrap-icons';

//Custom
.expand-on-hover {
    transition: all .25s ease-in-out;

    &:hover {
        transform: scale(1.1);
    }
}
.hero-1 {
    background: linear-gradient( rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65) ), url('/img/quail-run.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
}
.hero-2 {
    transition: all 3s ease-in-out;
    background: linear-gradient( rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65) ), url('/img/quail-run.jpg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
}
.hero-3 {
    background: linear-gradient( rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65) ), url('/img/quail-run-2.jpg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
}
.hero-post {
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
}
nav {
    button {
        outline: none!important;
        box-shadow: none!important;
    }
    .nav-item {
        transition: all .2s ease-in-out;
        border-bottom: 2px solid transparent;

        &.active {
            font-weight: bolder;

            .nav-link {
                color: black;
            }
        }

        &:hover {
            border-bottom: 2px solid black;
        }
    }
    .navbar-toggler {
        transition: all .2s ease-in-out;
        border: none!important;

        &:not(.collapsed) {
            transform: rotate(90deg);
        }
    }
    .navbar-collapse {
        display: flex;
        justify-content: end;

        @media all and (max-width: 991px){
            justify-content: start;
        }
    }
}
